import { FilterSortPageResponse, IGridRow } from '../../shared/grid.model';
export class SELPA implements IGridRow {
  rowId: string;
  code: string;
  constructor(init?: Partial<SELPA>) {
    Object.assign(this, init);
  }
}
export class SELPAItemsResponse extends FilterSortPageResponse<SELPA> {
  results: SELPA[];
  totalCount: number;
}

export class SELPAName implements IGridRow {
  rowId: string;
  name: string;
  caFiscalYearStart: number;
  caFiscalYearEnd?: number;
  code: string;
  constructor(init?: Partial<SELPAName>) {
    Object.assign(this, init);
  }
}

export class SELPATimePeriods implements IGridRow {
  rowId: string;
  code: string;
  caFiscalYearStart: number;
  caFiscalYearEnd?: number;
  constructor(init?: Partial<SELPAName>) {
    Object.assign(this, init);
  }
}

export class SELPADetailsNgRequest {
  code: string;

  constructor(init?: Partial<SELPADetailsNgRequest>) {
    Object.assign(this, init);
  }
}

export class SELPANamesResponse extends FilterSortPageResponse<SELPAName> {
  results: SELPAName[];
  totalCount: number;
}

export class SELPARelationship implements IGridRow {
  rowId: string;
  entityType: SELPAEntityType;
  selpaEntityCode: string;
  cdsCode: string;
  caFiscalYearStart: number;
  caFiscalYearEnd?: number;
  code: string;
  constructor(init?: Partial<SELPARelationship>) {
    Object.assign(this, init);
  }
}
export class SELPARelationshipsResponse extends FilterSortPageResponse<SELPARelationship> {
  results: SELPARelationship[];
  totalCount: number;
}

export class SELPATimePeriodResponse extends FilterSortPageResponse<SELPATimePeriods> {
  results: SELPATimePeriods[];
  totalCount: number;
}

export enum AddButtonTexts {
  TimePeriod = 'Time Period',
  Name = 'Name',
  Relationship = 'Relationship',
}

export enum SELPAEntityType {
  Unknown = 'Unknown' ,
  AU = 'AU'  ,
  Member = 'Member', 
}

export class SELPAEntityTypeNames {
  [SELPAEntityType: string]: string;
  constructor() {
    this[SELPAEntityType.Unknown] = 'Unknown';
    this[SELPAEntityType.AU] = 'AU';
    this[SELPAEntityType.Member] = 'Member';
  }
}
