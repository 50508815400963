import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MYPIOMenuItem } from '../shared/MYPIO.model';
import { PGMMenuItem } from '../shared/pgm.model';
import { CEFBMenuItem } from '../shared/cefb.model';
import { CharterSchoolInfoResponse } from '../shared/charter-school-info.model';
import { EmptyRequest } from '../shared/empty-request';
import { EmptyResponse } from '../shared/empty-response';
import { FormResponse } from '../shared/form.model';
import {
  FilterSortPageRequest,
  FilterSortPageResponse,
  FilterValuesRequest,
  FilterValuesResponse,
} from '../shared/grid.model';
import { IFCStatusesRequest, IFCStatusesResponse } from '../shared/ifc-status.model';
import { NgRequest } from '../shared/ng-request';
import { NgResponse } from '../shared/ng-response';
import { ReportingPeriods } from '../shared/reporting-periods';
import { SELPACodesPendingReviewResponse, SELPAReview, SELPAReviewRequest, SELPAReviewResponse } from '../shared/selpa-review.model';
import { SEMAIMenuItem } from '../shared/semai.model';
import { SubmissionsScopeApiResponse } from '../shared/statewide-batch-operation.model';
import {
  CloneSubmissionRequest,
  CloneSubmissionResponse,
  CountApiResponse,
  CreateDraftSubmissionRequest,
  CreateDraftSubmissionResponse,
  DeleteSubmissionResponse,
  FilteredSubmissionsApiResponse,
  LEACustomInformation,
  RestoreSubmissionResponse,
  SendSubmissionRequest,
  SendSubmissionResponse,
  SubmissionImportRequest,
  SubmissionImportResponse,
  SubmissionItemsResponse,
  SubmissionListApiRequest,
  SubmissionQueueApiRequest,
  SubmissionResponse,
  UpdateMultipleStateRequest,
  UpdateMultipleStateResponse,
  UpdateStateRequest,
  UpdateStateResponse,
  UpdateSubmissionItemRequest,
  UpdateSubmissionItemsRequest,
  UpdateSubmissionRequest,
  UpdateSubmissionResponse,
  UpdateSubmissionsResponse,
  UserDataRequestScopes
} from '../shared/submission.model';
import { TRCExplanationsUpdateRequest, TRCLogWithExplanationGroupResponse, TRCLogWithExplanationsRequest } from '../shared/trc/trc-log.model';
import { UAPacketTrackingLogApiRequest } from '../shared/ua-packet-tracking-log.model';
import { CharterSchoolsReviewListApiResponse } from '../tools/charter-schools-review-list/charter-schools-review.model';
import { CheckSubmissionStatusFilerRequest, CheckSubmissionStatusFilerResponse } from '../tools/check-submission-status/check-submission-status.model';
import { ICRateDifferencesRequest, ICRateDifferencesResponse } from '../tools/ic-rate-differences/ic-rate-differences.model';
import { FilersResponse, MaintainFilersRequest, MaintainFilersResponse } from '../tools/maintain-filers/filers.model';
import { UAPacketTrackingLog } from '../tools/ua-packet-tracking-log/ua-packet-tracking-log.model';
import { ViewFilersExpectedToFileResponse } from '../tools/view-filers/view-filers-expected-to-file.model';
import { ActiveUASubmissionsExistApiResponse } from './../tools/ua-submission-checklist/ua-submission-checklist.model';
import { HttpClientService } from './http-client.service';
import { HttpErrorHandler } from './http-error-handler';
import { ViewMissedSubmissionsResponse } from "../tools/pre-publish-operations/missed-submission-table/missed-submission.model";
import { MissedSubmissionsRequest } from '../tools/pre-publish-operations/pre-publish-operations.model';

@Injectable()
export class SubmissionService extends HttpClientService {
  constructor(readonly httpClient: HttpClient,
    readonly httpErrorHandler: HttpErrorHandler) {
    super('SubmissionService', httpClient, httpErrorHandler);
  }

  getSubmission(submissionNumber: string, scope: UserDataRequestScopes): Observable<NgResponse<SubmissionResponse>> {
    return this.get<SubmissionResponse>('getSubmission', `api/Submissions/GetOne/${submissionNumber}/${scope}`);
  }

  fetchActiveUAItemsExist(): Observable<NgResponse<ActiveUASubmissionsExistApiResponse>> {
    return this.get<ActiveUASubmissionsExistApiResponse>('getActiveUASubmissions', `api/Submissions/GetSubmissionsInActiveState`);
  }

  fetchListItems(submissionListRequest: NgRequest<FilterSortPageRequest<SubmissionListApiRequest>>): Observable<NgResponse<FilteredSubmissionsApiResponse>> {
    return this.postBody<FilterSortPageRequest<SubmissionListApiRequest>, FilteredSubmissionsApiResponse>('fetchListItems', 'api/Submissions/ListItems', submissionListRequest);
  }

  fetchUAListItems(submissionListRequest: NgRequest<FilterSortPageRequest<SubmissionListApiRequest>>): Observable<NgResponse<FilteredSubmissionsApiResponse>> {
    return this.postBody<FilterSortPageRequest<SubmissionListApiRequest>, FilteredSubmissionsApiResponse>('fetchUAListItems', 'api/Submissions/ListUAItems', submissionListRequest);
  }

  fetchListFilterValues(submissionListRequest: NgRequest<FilterValuesRequest<SubmissionListApiRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<SubmissionListApiRequest>, FilterValuesResponse>('fetchListFilterValues', 'api/Submissions/ListFilterValues', submissionListRequest);
  }

  updateSubmissionState(updateStateRequest: NgRequest<UpdateStateRequest>): Observable<NgResponse<UpdateStateResponse>> {
    return this.postBody<UpdateStateRequest, UpdateStateResponse>('updateState', 'api/Submissions/UpdateState', updateStateRequest);
  }

  updateMultipleSubmissionStates(updateMultipleStateRequest: NgRequest<UpdateMultipleStateRequest>): Observable<NgResponse<UpdateMultipleStateResponse>> {
    return this.postBody<UpdateMultipleStateRequest, UpdateMultipleStateResponse>('updateMultipleStates', 'api/Submissions/UpdateMultipleStates', updateMultipleStateRequest);
  }

  updateSubmissionItems(updateSubmissionsRequest: NgRequest<UpdateSubmissionItemsRequest>): Observable<NgResponse<UpdateSubmissionsResponse>> {
    return this.putBody<UpdateSubmissionItemsRequest, UpdateSubmissionsResponse>('updateSubmissionItems', 'api/Submissions/UpdateSubmissionItems', updateSubmissionsRequest);
  }

  updateSubmissionItem(updateSubmissionRequest: NgRequest<UpdateSubmissionItemRequest>): Observable<NgResponse<UpdateSubmissionResponse>> {
    return this.putBody<UpdateSubmissionItemRequest, UpdateSubmissionResponse>('updateSubmissionItem', 'api/Submissions/UpdateSubmissionItem', updateSubmissionRequest);
  }

  getSubmissionDraftsCount(): Observable<NgResponse<CountApiResponse>> {
    return this.get<CountApiResponse>('getSubmissionDraftsCount', 'api/Submissions/GetSubmissionDraftsCount');
  }

  fetchQueueItems(queueName: string, itemsUrlPath: string, submissionQueueRequest: NgRequest<FilterSortPageRequest<SubmissionQueueApiRequest>>): Observable<NgResponse<FilteredSubmissionsApiResponse>> {
    return this.postBody<FilterSortPageRequest<SubmissionQueueApiRequest>, FilteredSubmissionsApiResponse>(`fetch${queueName}QueueItems`, itemsUrlPath, submissionQueueRequest);
  }

  fetchQueueFilterValues(queueName: string, filterValuesUrlPath: string, submissionQueueRequest: NgRequest<FilterValuesRequest<SubmissionQueueApiRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<SubmissionQueueApiRequest>, FilterValuesResponse>(`fetch${queueName}QueueFilterValues`, filterValuesUrlPath, submissionQueueRequest);
  }

  deleteSubmission(id: string): Observable<NgResponse<DeleteSubmissionResponse>> {
    return this.delete<DeleteSubmissionResponse>('delete', `api/Submissions/Delete/${id}`);
  }

  cloneSubmission(submissionRequest: NgRequest<CloneSubmissionRequest>): Observable<NgResponse<CloneSubmissionResponse>> {
    return this.postBody<CloneSubmissionRequest, CloneSubmissionResponse>('cloneSubmission', 'api/Submissions/CloneSubmission', submissionRequest);
  }

  sendSubmission(submissionRequest: NgRequest<SendSubmissionRequest>): Observable<NgResponse<SendSubmissionResponse>> {
    return this.postBody<SendSubmissionRequest, SendSubmissionResponse>('sendSubmission', 'api/Submissions/SendSubmission', submissionRequest);
  }

  createDraftSubmission(submissionRequest: NgRequest<CreateDraftSubmissionRequest>): Observable<NgResponse<CreateDraftSubmissionResponse>> {
    return this.postBody<CreateDraftSubmissionRequest, CreateDraftSubmissionResponse>('createDraftSubmission', 'api/Submissions/CreateDraft', submissionRequest);
  }

  importSubmission(submissionImportRequest: NgRequest<SubmissionImportRequest>, filesInfo: Array<File>): Observable<NgResponse<SubmissionImportResponse>> {
    const filesToUpload: FormData = new FormData();
    filesToUpload.append('files', filesInfo[0], filesInfo[0].name);
    const url = submissionImportRequest.runMode
      ? `api/Submissions/Upload/${encodeURIComponent(submissionImportRequest.request.datasetName)}/${submissionImportRequest.request.isOfficialImport}/${submissionImportRequest.request.reportingPeriod}/${submissionImportRequest.request.datasetType}/${submissionImportRequest.runMode}`
      : `api/Submissions/Upload/${encodeURIComponent(submissionImportRequest.request.datasetName)}/${submissionImportRequest.request.isOfficialImport}/${submissionImportRequest.request.reportingPeriod}/${submissionImportRequest.request.datasetType}`;

    return this.postFile<SubmissionImportResponse>('importSubmission', url, filesToUpload);
  }

  getFilers(): Observable<NgResponse<FilersResponse>> {
    return this.get<FilersResponse>('getFilers', `api/Submissions/Filers`);
  }

  fetchCheckSubmissionStatusItems(request: NgRequest<FilterSortPageRequest<CheckSubmissionStatusFilerRequest>>): Observable<NgResponse<CheckSubmissionStatusFilerResponse>> {
    return this.postBody<FilterSortPageRequest<CheckSubmissionStatusFilerRequest>, CheckSubmissionStatusFilerResponse>(
      'fetchCheckSubmissionStatusItems',
      `api/Filers/UAFilers/Items`,
      request
    );
  }

  fetchCheckSubmissionStatusFilterValues(request: NgRequest<FilterValuesRequest<CheckSubmissionStatusFilerRequest>>) {
    return this.postBody<FilterValuesRequest<CheckSubmissionStatusFilerRequest>, FilterValuesResponse>(
      'fetchCheckSubmissionStatusFilterValues',
      `api/Filers/UAFilers/FilterValues`,
      request
    );
  }

  fetchFilerItems(request: NgRequest<FilterSortPageRequest<MaintainFilersRequest>>): Observable<NgResponse<MaintainFilersResponse>> {
    return this.postBody<FilterSortPageRequest<MaintainFilersRequest>, MaintainFilersResponse>('fetchItemsAsync', 'api/Filers/MaintainFilers/Items', request);
  }

  fetchFilerFilterValues(request: NgRequest<FilterValuesRequest<MaintainFilersRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<MaintainFilersRequest>, FilterValuesResponse>('fetchFilerFilterValues', 'api/Filers/MaintainFilers/FilterValues', request);
  }

  exportExcelFilers(request: NgRequest<FilterSortPageRequest<MaintainFilersRequest>>): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(
      `api/Filers/MaintainFilers/Excel`,
      request,
      {
        observe: 'response',
        responseType: 'blob'
      });
  }

  getIFCStatuses(request: NgRequest<FilterSortPageRequest<IFCStatusesRequest>>): Observable<NgResponse<IFCStatusesResponse>> {
    return this.postBody<FilterSortPageRequest<IFCStatusesRequest>, IFCStatusesResponse>('getIFCStatuses', 'api/IFCStatuses/Items', request);
  }

  getIFCStatusesFilterValues(request: NgRequest<FilterValuesRequest<IFCStatusesRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<IFCStatusesRequest>, FilterValuesResponse>('getIFCStatusesFilterValues', 'api/IFCStatuses/FilterValues', request);
  }

  printIFCStatuses(request: NgRequest<FilterValuesRequest<IFCStatusesRequest>>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<FilterValuesRequest<IFCStatusesRequest>>('printIFCStatuses', 'api/IFCStatuses/PDF', request);
  }

  exportIFCStatuses(request: NgRequest<FilterSortPageRequest<IFCStatusesRequest>>): Observable<HttpResponse<Blob>> {
    return this.httpClient.post('api/IFCStatuses/Excel', request, { observe: 'response', responseType: 'blob' });
  }

  fetchICRateDifferenceItems(request: NgRequest<FilterSortPageRequest<ICRateDifferencesRequest>>): Observable<NgResponse<ICRateDifferencesResponse>> {
    return this.postBody<FilterSortPageRequest<ICRateDifferencesRequest>, ICRateDifferencesResponse>(
      'fetchICRateDifferencesItems',
      `api/ICRateDifferences/Items`,
      request
    );
  }

  fetchICRateDifferenceFilterValues(request: NgRequest<FilterValuesRequest<ICRateDifferencesRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<ICRateDifferencesRequest>, FilterValuesResponse>(
      'fetchICRateDifferencesFilterValues',
      'api/ICRateDifferences/FilterValues',
      request);
  }

  exportExcelICRateDifferences(request: NgRequest<FilterSortPageRequest<ICRateDifferencesRequest>>): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(
      `api/ICRateDifferences/Excel`,
      request,
      {
        observe: 'response',
        responseType: 'blob'
      });
  }

  exportPDFICRateDifferences(request: NgRequest<FilterSortPageRequest<ICRateDifferencesRequest>>): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(
      `api/ICRateDifferences/PDF`,
      request,
      {
        observe: 'response',
        responseType: 'blob'
      });
  }

  fetchFilersExpectedToFileListItems(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<NgResponse<ViewFilersExpectedToFileResponse>> {
    return this.postBody<FilterSortPageRequest<EmptyRequest>, ViewFilersExpectedToFileResponse>('fetchFilersExpectedToFileListItems', `api/ExpectedToFile/Items`, request);
  }

  fetchFilersExpectedToFileFilterValues(request: NgRequest<FilterValuesRequest<EmptyRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<EmptyRequest>, FilterValuesResponse>('fetchFilersExpectedToFileFilterValues', 'api/ExpectedToFile/FilterValues', request);
  }

  fullExportFilersExpectedToFile(): Observable<HttpResponse<Blob>> {
    return this.getBlobResponse('partialExportExcelFilersExpectedToFile', 'api/ExpectedToFile/Export/Full');
  }

  partialExportFilersExpectedToFile(request: NgRequest<FilterSortPageRequest<EmptyRequest>>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<FilterSortPageRequest<EmptyRequest>>('partialExportExcelFilersExpectedToFile', 'api/ExpectedToFile/Export/Partial', request);
  }

  fullImportFilersExpectedToFile(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('fullImportFilersExpectedToFile', 'api/ExpectedToFile/Import/Full', formData);
  }

  partialImportFilersExpectedToFile(file: File): Observable<NgResponse<EmptyResponse>> {
    const formData: FormData = new FormData();
    formData.append('importFile', file, file.name);
    return this.postFile<EmptyResponse>('partialImportFilersExpectedToFile', 'api/ExpectedToFile/Import/Partial', formData);
  }

  clearFilers(submissionId: string): Observable<NgResponse<FilersResponse>> {
    return this.put<FilersResponse>('clearFilers', `api/Submissions/ClearFilers/${submissionId}`);
  }

  getCharterSchoolsReviewList(fiscalYear: string): Observable<NgResponse<CharterSchoolsReviewListApiResponse>> {
    return this.get<CharterSchoolsReviewListApiResponse>('getCharterSchoolsReviewList', 'api/CharterSchoolsReviewList');
  }

  getSubmissionsPromotedToCDE(fiscalYear: string, cdsCode: string): Observable<NgResponse<SubmissionItemsResponse>> {
    return this.get<SubmissionItemsResponse>('getSubmissionsPromotedToCDE', `api/Submissions/SubmissionsPromotedToCDE/${fiscalYear}/${cdsCode}`);
  }

  getCharterSchoolInfo(cdsCode: string): Observable<NgResponse<CharterSchoolInfoResponse>> {
    return this.get<CharterSchoolInfoResponse>('getCharterSchoolInfo', `api/Submissions/CharterSchoolInfo/${cdsCode}`);
  }

  mergeSelpaData(memberSubmissionId: string, auSubmissionId: string, code: string, memberCode: string, selpaFormId: string, selpaId: string): Observable<NgResponse<FormResponse>> {
    return this.get<FormResponse>('selpaMerge', `api/Submissions/SELPAAU/${memberSubmissionId}/${auSubmissionId}/${code}/${memberCode}/${selpaFormId}/${selpaId}/Merge`);
  }

  updatePendingICRReview(updatePendingICRRequest: NgRequest<UpdateSubmissionRequest>): Observable<NgResponse<UpdateSubmissionResponse>> {
    return this.postBody<UpdateSubmissionRequest, UpdateSubmissionResponse>('updatePendingICRReview', 'api/Submissions/UpdatePendingICRReview', updatePendingICRRequest);
  }

  updateSignedCertificatesReceived(updateSignedCertificatesReceivedRequest: NgRequest<UpdateSubmissionRequest>): Observable<NgResponse<UpdateSubmissionResponse>> {
    return this.postBody<UpdateSubmissionRequest, UpdateSubmissionResponse>('updateSignedCertificatesReceivedRequest', 'api/Submissions/UpdateSignedCertificatesReceived', updateSignedCertificatesReceivedRequest);
  }

  restoreSubmission(payloadId: string): Observable<NgResponse<RestoreSubmissionResponse>> {
    return this.post<RestoreSubmissionResponse>('restoreSubmission', `api/Submissions/RestoreSubmission/${payloadId}`);
  }

  getTRCLogWithExplanations(request: NgRequest<TRCLogWithExplanationsRequest>): Observable<NgResponse<TRCLogWithExplanationGroupResponse>> {
    return this.postBody<TRCLogWithExplanationsRequest, TRCLogWithExplanationGroupResponse>('trcLogWithExplanations/get', 'api/TRCs/LogsWithExplanations', request);
  }

  saveTRCExplanations(trcLogUpdateRequest: NgRequest<TRCExplanationsUpdateRequest>): Observable<NgResponse<EmptyResponse>> {
    return this.putBody<TRCExplanationsUpdateRequest, EmptyResponse>('trcExplanations/Update', 'api/TRCs/Explanations/', trcLogUpdateRequest);
  }

  SELPAReview(request: NgRequest<SELPAReviewRequest>): Observable<NgResponse<SELPAReview>> {
    return this.postBody<SELPAReviewRequest, SELPAReview>('selpaReview', 'api/Submissions/SELPAReview', request);
  }

  updateSELPAReview(request: NgRequest<SELPAReview>): Observable<NgResponse<SELPAReviewResponse>> {
    return this.putBody<SELPAReview, SELPAReviewResponse>('updateSELPAReview', 'api/Submissions/UpdateSELPAReview', request);
  }

  selpaCodesPendingReview(): Observable<NgResponse<SELPACodesPendingReviewResponse>> {
    return this.get<SELPACodesPendingReviewResponse>('selpaCodesPendingReview', 'api/Submissions/SELPACodesPendingReview');
  }

  cefbFormFetchMenu(submissionNumber: string): Observable<NgResponse<CEFBMenuItem[]>> {
    return this.get<any>('cefbFormFetchMenu', `api/CEFBForm/Menu/${submissionNumber}`);
  }

  mypioFormFetchMenu(submissionNumber: string): Observable<NgResponse<MYPIOMenuItem[]>> {
    return this.get<any>('mypioFormFetchMenu', `api/MYPIOForm/Menu/${submissionNumber}`);
  }

  pgmFormFetchMenu(submissionNumber: string): Observable<NgResponse<PGMMenuItem[]>> {
    return this.get<any>('pgmFormFetchMenu', `api/PGMForm/Menu/${submissionNumber}`);
  }

  semaiFormFetchMenu(submissionNumber: string): Observable<NgResponse<SEMAIMenuItem[]>> {
    return this.get<any>('semaiFormFetchMenu', `api/SEMAIForm/Menu/${submissionNumber}`);
  }

  fetchUAPacketTrackingLogItems(request: NgRequest<FilterSortPageRequest<UAPacketTrackingLogApiRequest>>): Observable<NgResponse<FilterSortPageResponse<UAPacketTrackingLog>>> {
    return this.postBody<FilterSortPageRequest<UAPacketTrackingLogApiRequest>, FilterSortPageResponse<UAPacketTrackingLog>>('fetchUAPacketTrackingLogItems', `api/UAPacketTrackingLog/Items`, request);
  }

  fetchUAPacketTrackingLogFilterValues(request: NgRequest<FilterValuesRequest<UAPacketTrackingLogApiRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<UAPacketTrackingLogApiRequest>, FilterValuesResponse>('fetchUAPacketTrackingLogFilterValues', 'api/UAPacketTrackingLog/FilterValues', request);
  }

  printUAPacketTrackingLogPreview(request: NgRequest<FilterValuesRequest<UAPacketTrackingLogApiRequest>>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<FilterValuesRequest<UAPacketTrackingLogApiRequest>>('printUAPacketTrackingLogPreview', 'api/UAPacketTrackingLog/Preview', request);
  }

  exportUAPacketTrackingLogExcel(request: NgRequest<FilterValuesRequest<UAPacketTrackingLogApiRequest>>): Observable<HttpResponse<Blob>> {
    return this.postBodyWithBlobResponse<FilterValuesRequest<UAPacketTrackingLogApiRequest>>('exportUAPacketTrackingLogExcel', 'api/UAPacketTrackingLog/Export', request);
  }

  getReport(reportId: string, submissionNumber?: string): Observable<HttpResponse<Blob>> {
    const route = submissionNumber
      ? `api/Reports/GetReport${reportId}/${submissionNumber}`
      : `api/Reports/GetReport${reportId}`;
    return this.httpClient.get(route, { observe: 'response', responseType: 'blob' });
  }

  getReportWithReportingPeriod(reportId: string, reportingPeriod: ReportingPeriods): Observable<HttpResponse<Blob>> {
    const route = `api/Reports/GetReport${reportId}/${reportingPeriod}`;
    return this.httpClient.get(route, { observe: 'response', responseType: 'blob' });
  }

  getReportWithCounty(reportId: string, county: string): Observable<HttpResponse<Blob>> {
    const route = `api/Reports/GetReport${reportId}/${county}`;
    return this.httpClient.get(route, { observe: 'response', responseType: 'blob' });
  }

  getLEACustomInformation(submissionNumber: string): Observable<NgResponse<LEACustomInformation>> {
    return this.get<LEACustomInformation>('getLEACustomInformation', `api/Submission/${submissionNumber}/LEACustomInformation`);
  }

  getSubmissionsScope(): Observable<NgResponse<SubmissionsScopeApiResponse>> {
    return this.get<SubmissionsScopeApiResponse>('getSubmissionsScope', 'api/Submissions/Scope');
  }

  fetchPublishingMissedSubmissionItems(request: NgRequest<FilterSortPageRequest<MissedSubmissionsRequest>>): Observable<NgResponse<ViewMissedSubmissionsResponse>> {
    return this.postBody<FilterSortPageRequest<MissedSubmissionsRequest>, ViewMissedSubmissionsResponse>('fetchItems', 'api/PublishingMissedSubmissions/Items', request);
  }

  fetchPublishingMissedSubmissionsFilterValues(request: NgRequest<FilterValuesRequest<MissedSubmissionsRequest>>): Observable<NgResponse<FilterValuesResponse>> {
    return this.postBody<FilterValuesRequest<MissedSubmissionsRequest>, FilterValuesResponse>('fetchFilterValues', 'api/PublishingMissedSubmissions/FilterValues', request);
  }

  exportExcelSubmissions(request: NgRequest<FilterSortPageRequest<SubmissionListApiRequest>>): Observable<HttpResponse<Blob>> {
    return this.httpClient.post(
      `api/Submissions/Excel`,
      request,
      {
        observe: 'response',
        responseType: 'blob'
      });
  }
}
